<template>
  <div class="nav-side-area" id="navSideArea">
    <div class="nav-side-close-box">
      <div class="nav-side-close" id="navSideClose">
        <img src="@/assets/img/icon_m_close.svg" alt="" />
      </div>
    </div>
    <div class="nav-side-logo-box">
      <div class="nav-side-logo">
        <img src="@/assets/img/nav_logo.svg" alt="" />
      </div>
      <div class="nav-side-social-box">
        <router-link to="/meteorology" class="nav-side-social">
          <img src="@/assets/img/icon_cloud.svg" alt="" />
        </router-link>
        <router-link to="/login" class="nav-side-social" v-if="!isLogin">
          <img src="@/assets/img/icon_acc.svg" alt="" />
          <!-- icon_login -->
        </router-link>
        <router-link to="/account" class="nav-side-social" v-if="isLogin">
          <img src="@/assets/img/icon_acc.svg" alt="" />
        </router-link>
        <!-- <a class="nav-side-social" href="account.html">
          <img src="@/assetsimg/icon_acc.svg" alt="" />
        </a> -->
        <!-- 維護更新按鈕  -->
        <!-- <a class="nav-side-social" href="###">
          <img src="@/assets/img/icon_set.svg" alt="" />
        </a> -->
      </div>
    </div>
    <div class="nav-side-link-area">
      <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon">
            <img src="@/assets/img/icon_city.svg" alt="" />
          </div>
          <div class="tit">認識本會</div>
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a href="https://www.ipcf.org.tw/zh-TW/About/KnowClub" target="_blank"
          >關於本會</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/PartArticle/Html/21070810370518447"
          target="_blank"
          >組織架構</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/Directors/List"
          target="_blank"
          >董監事及經營團隊</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/PartArticle/Html/21070810341898158"
          target="_blank"
          >交通資訊</a
        >
      </div>
      <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon">
            <img src="@/assets/img/icon_books.svg" alt="" />
          </div>
          <div class="tit">公開資訊</div>
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a href="https://www.ipcf.org.tw/zh-TW/About/Public" target="_blank"
          >組織人事</a
        ><a href="https://www.ipcf.org.tw/zh-TW/About/Public" target="_blank"
          >營運業務</a
        ><a href="https://www.ipcf.org.tw/zh-TW/About/Public" target="_blank"
          >法令規章</a
        ><a href="https://www.ipcf.org.tw/zh-TW/About/Public" target="_blank"
          >監督機制</a
        ><a href="https://www.ipcf.org.tw/zh-TW/About/Public" target="_blank"
          >製播節目</a
        ><a href="https://www.ipcf.org.tw/zh-TW/About/Public" target="_blank"
          >研究報告</a
        >
      </div>
      <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon">
            <img src="@/assets/img/icon_library.svg" alt="" />
          </div>
          <div class="tit">會務相關</div>
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a href="https://www.ipcf.org.tw/zh-TW/Festival/List" target="_blank"
          >歲時祭儀</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/PartArticle/List/21072614333000012"
          target="_blank"
          >實習申請</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/PartArticle/List/21072614345693844"
          target="_blank"
          >業務服務</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/BroadCast/List"
          target="_blank"
          >託播查詢</a
        >
        <a
          href="https://www.ipcf.org.tw/zh-TW/About/ProgramCopyright"
          target="_blank"
          >節目版權目錄</a
        >
      </div>
      <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon">
            <img src="@/assets/img/icon_people.svg" alt="" />
          </div>
          <div class="tit">觀眾服務</div>
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a
          href="https://www.ipcf.org.tw/zh-TW/About/Index/21051417565005720"
          target="_blank"
          >參訪基金會</a
        ><a href="https://www.ipcf.org.tw/zh-TW/About/Contact" target="_blank"
          >線上客服</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/About/Feedback"
          target="_blank"
          >意見反應</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/About/Index/21070910110531248"
          target="_blank"
          >社群</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/About/Index/21061616200029064"
          target="_blank"
          >收視/收聽方式</a
        >
      </div>
      <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon">
            <img src="@/assets/img/icon_vintage.svg" alt="" />
          </div>
          <div class="tit">文化藝術</div>
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a
          href="http://www.pulima.com.tw/Pulima/index.aspx"
          target="_blank"
          >藝術</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/PartArticle/List/21072111021458833"
          target="_blank"
          >補助</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/Article/List/21061518083587154"
          target="_blank"
          >交流參訪</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/Article/List/21061518093796550"
          target="_blank"
          >出版品</a
        >
        <a href="http://story.ipcf.org.tw/" target="_blank">746山海誌</a>
        <a
          href="https://insight.ipcf.org.tw"
          target="_blank"
          >原視界IPCF雜誌</a
        >
      </div>
      <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon"><img src="@/assets/img/icon_tv.svg" alt="" /></div>
          <div class="tit">最新消息</div>
          <!-- 原視新聞 -->
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a
          href="https://www.ipcf.org.tw/zh-TW/Article/List/21050416184989967?categoryId=21072817124057637"
          target="_blank"
          >本會公告</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/Article/List/21050416184989967?categoryId=21072617385667262"
          target="_blank"
          >新聞稿</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/Article/List/21050416184989967?categoryId=21082010465131256"
          target="_blank"
          >徵才專區</a
        ><a
          href="https://www.ipcf.org.tw/zh-TW/Article/List/21050416184989967?categoryId=21091117434624702"
          target="_blank"
          >招標公告</a
        >
      </div>
      <!-- <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon">
            <img src="@/assets/img/icon_camera.svg" alt="" />
          </div>
          <div class="tit">原視節目</div>
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a href="###" target="_blank">原視節目</a
        ><a href="###" target="_blank">組織架構</a
        ><a href="###" target="_blank">董監事及經營團隊</a
        ><a href="###" target="_blank">交通資訊</a>
      </div>
      <div class="nav-side-link-tit">
        <div class="link-info">
          <div class="icon">
            <img src="@/assets/img/icon_radio.svg" alt="" />
          </div>
          <div class="tit">Alian 廣播電台</div>
        </div>
        <div class="nav-side-link-close">
          <img src="@/assets/img/icon_plus.svg" alt="" />
        </div>
      </div>
      <div class="nav-side-link-box">
        <a href="###" target="_blank">廣播電台</a
        ><a href="###" target="_blank">組織架構</a
        ><a href="###" target="_blank">董監事及經營團隊</a
        ><a href="###" target="_blank">交通資訊</a>
      </div> -->
    </div>
    <div class="nav-footer-box">
      財團法人原住民族文化事業基金會 版權所有<br />
      Copyright © 2021 Indigenous Peoples Cultural Foundation All Rights
      Reserved.<br />
      電話：<a href="tel:(02)2788-1600">(02)2788-1600</a> 傳真：<a href="tel:(02)2788-1500">(02)2788-1500</a><br/>
      地址：台北市南港區重陽路 120 號 5
      樓
    </div>
    <router-link to="/IframeVideo" class="nav-btn">
      .
    </router-link>
    <router-link to="/LoginTest" class="nav-btn" style="float: right; width: 30px; height: 30px">
      .
    </router-link>
  </div>
</template>
<script>
export default {
  name: "NavSide",
  data() {
    return {
      isLogin: true,
    };
  },
  mounted() {
    //=== 判斷是否有登入狀態 ===
    this.userInfo.userToken == ""
      ? (this.isLogin = false)
      : (this.isLogin = true);
  },
  computed: {
    //=== 取的使用者資訊 ===
    userInfo: {
      get() {
        // return this.$store.getters["userInfoGet"];
        return this.$store.state.userInfo;
      },
      set(val) {
        // this.$store.dispatch("GETTOUSERINFO", val);
        this.$store.commit("SETUSERINFO", val);
      },
    },
  },
};
</script>