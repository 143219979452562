<template>
  <div>
    <div class="lg-note-area" id="lgNote">
      <div class="lg-note-wrap">
        <div class="lg-note-box">
          <p>-</p>
        </div>
        <div class="btn-box">
          <div class="btn-w w-btn" id="lgNoteClose">確定</div>
        </div>
      </div>
    </div>
    <nav>
      <div class="nav-box nav-l-box">
        <a class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </a>
        <router-link to="/" class="nav-btn">
          <img src="@/assets/img/icon_home.svg" alt="" />
        </router-link>
      </div>
      <div class="nav-box nav-r-box">
        <!-- <a class="nav-btn nav-bell" href="###">
          <img src="@/assets/img/icon_bell.svg" alt="" />
          <div class="bell-num">13</div>
        </a> -->
        <router-link class="nav-btn" to="/search">
          <img src="@/assets/img/icon_src.svg" alt="" />
        </router-link>
        <div class="nav-btn" id="hamBtn">
          <img src="@/assets/img/icon_ham.svg" alt="" />
        </div>
      </div>
    </nav>
    <div class="header-bg"></div>
    <div class="footer-bg"></div>
  </div>
</template>
<script>
export default {
  name: "NavTop",
  data() {
    return {};
  },
  methods: {
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
      // this.upLoadImgRegister();
    },
  },
};
</script>