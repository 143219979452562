import axios from "axios";
import router from '../router';

//=== 後台 ===
const apiProxy = axios.create({
    baseURL: 'https://app.ipcf.org.tw/api/banner/enter',
    //=== 開發環境使用 ===
    // baseURL: '/api/banner/enter', //設定一般路徑都走至/api ， vue.config.js 設定 proxy 走跨域請求
    // headers: {
    //     'X-Requested-With': 'XMLHttpRequest',
    // },
    //=== 同意cookie ===
    // withCredentials: true,
    // transformResponse: [function (data) {
    //     // 对 data 进行任意转换处理
    //     console.log('這看看');
    //     console.log(data);
    //     return data;
    // }],
});
//=== 單元中幅廣告 ===
const apiProxyBannerMiddle = axios.create({
    baseURL: 'https://app.ipcf.org.tw/api/banner/middle',
    //=== 開發環境使用 ===
    // baseURL: '/api/banner/middle', //設定一般路徑都走至/api ， vue.config.js 設定 proxy 走跨域請求
    // headers: {
    //     'X-Requested-With': 'XMLHttpRequest',
    // },
    //=== 同意cookie ===
    // withCredentials: true,
});
//=== 單元底部廣告 ===
const apiProxyBannerBottom = axios.create({
    baseURL: 'https://app.ipcf.org.tw/api/banner/bottom',
    //=== 開發環境使用 ===
    // baseURL: '/api/banner/bottom', //設定一般路徑都走至/api ， vue.config.js 設定 proxy 走跨域請求
    // headers: {
    //     'X-Requested-With': 'XMLHttpRequest',
    // },
    //=== 同意cookie ===
    // withCredentials: true,
});
const apiProxyMarket = axios.create({
    baseURL: 'https://app.ipcf.org.tw/api/market',
    //=== 開發環境使用 ===
    // baseURL: '/api/market', //設定一般路徑都走至/api ， vue.config.js 設定 proxy 走跨域請求
    // headers: {
    //     'X-Requested-With': 'XMLHttpRequest',
    // },
    //=== 同意cookie ===
    // withCredentials: true,
});


const apiProxyWeb = axios.create({
    baseURL: 'https://webdemo.cc-work.com/ipcfAPI/api',//'http://3.114.243.73:8000',
    // baseURL: 'https://www.ipcf.org.tw/IWebApi/api', //<<<正式機位置
    // baseURL: 'http://18.182.117.182/api',
    //=== 開發環境使用 ===
    // baseURL: '/api', //設定一般路徑都走至/api ， vue.config.js 設定 proxy 走跨域請求
    headers: {
        'CAPI-ID': 'ipcf',
        'CAPI-Key': '!qazxsw2',
        'Content-Type': 'application/json',

        // 'X-Requested-With': 'XMLHttpRequest',
    },
    // responseType: 'text',
    //=== 同意cookie ===
    withCredentials: true,
    transformResponse: [
        ...axios.defaults.transformRequest//將收到的json response數值 轉為 JSON.stringify
    ]
    // transformResponse: [function (data) {
    //     // 对 data 进行任意转换处理
    //     console.log('這看看');
    //     console.log(data);
    //     return data;
    // }],
});
//== 需要將原本的資料經由bigNumber轉換 ==
var JSONbig = require("json-bigint");
apiProxyWeb.interceptors.response.use(function (response) {
    var turnData = JSONbig.parse(response.data);
    response.data = turnData;
    return response;
}, function (error) {
    return Promise.reject(error);
})
//攔截器 - 把回傳的資料先攔截下來處理，會再回傳
apiProxy.interceptors.response.use(function (response) {
    // Do something with response data
    // console.log('回應');
    // console.log(response);
    return response;
}, function (error) {
    // Do something with response error
    console.log('錯誤');
    console.log(error);
    console.log(error.request.response);
    // console.log(JSON.parse(error.request.response).message);
    console.log(error.request.status);
    // if (error.request.status == '401') {
    //     console.log('尚未登入，回首頁');
    //     alert("已登出或尚未登入，請重新登入。");
    //     //== 要清掉登入者資訊 ==
    //     localStorage.removeItem("accInfo");
    //     router.push("/").catch((err) => {
    //         err;
    //     });
    // } else if (error.request.status == '400' && JSON.parse(error.request.response).message == "No permission") {
    //     console.log('無使用權限，回首頁');
    //     //== 使用到無權限項目，請重新登入 ==
    //     apiLogout()
    //         .then((result) => {
    //             // console.log(result.data);
    //             if (result.data.message == "Success") {
    //                 alert("無此使用權限，請重新登入。");
    //                 console.log('無使用權限，登出');
    //                 //== 要清掉登入者資訊 ==
    //                 localStorage.removeItem("accInfo");
    //                 router.push("/").catch((err) => {
    //                     err;
    //                 });
    //             }
    //             //   context.commit("SETLIST", result.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //     //== 要清掉登入者資訊 ==
    //     // localStorage.removeItem("accInfo");
    //     // router.push("/");
    // }
    return Promise.reject(error);
});
//== 確認ios app 版本 ==
export const apiCheckVersion = (data) => apiProxyWeb.get('https://itunes.apple.com/tw/lookup?id=1061590108', data);

//== 登入/登出 ==
export const apiLogin = (data) => apiProxyWeb.post('/MemberShipLogin/CheckLogin', data);
export const apiLoginThree = (data) => apiProxyWeb.post('/MemberShipLogin/SocialLogin', data);

// export const apiLogin = (data) => apiProxy.post('/login', data);
export const apiLogout = () => apiProxy.post('/logout');
export const apiTest = (data) => apiProxyWeb.post('/Program/GetProgram', data,);

//== 註冊 ==
export const apiImgUpload = (data) => apiProxyWeb.post('/ImageUpload/Post', data);
export const apiGetClanList = () => apiProxyWeb.get('/MemberShip/GetIndigenousCategories');
export const apiRegister = (data) => apiProxyWeb.post('/MemberShipSignUp/Post', data);
export const apiUserEdit = (data) => apiProxyWeb.post('/MemberShip/Modify', data);

//=== 節目 ===
export const apiGetCategorys = (data) => apiProxyWeb.post('/Program/GetCategorys', data);
export const apiGetProgramList = (data) => apiProxyWeb.post('/Program/GetProgramList', data);
export const apiGetProgramDetail = (data) => apiProxyWeb.post('/Program/GetProgram', data);
export const apiGetProgramEpisodeList = (data) => apiProxyWeb.post('/Program/GetProgramEpisodeList', data);
export const apiGetProgramEpisode = (data) => apiProxyWeb.post('/Program/GetProgramEpisode', data);
export const apiGetRadioDetail = (data) => apiProxyWeb.post('/Program/GetRadio', data);
export const apiGetRadioEpisode = (data) => apiProxyWeb.post('/Program/GetRadioEpisode', data);

//=== 主持人列表 ===
export const apiGetHostList = (data) => apiProxyWeb.post('/Host/GetHostList', data);

//=== 廣告列表和分類 ===
export const apiGetAdmCategorys = () => apiProxyWeb.get('/Banner/GetBannerMenuType');
export const apiGetAdmList = (data) => apiProxyWeb.post('/Banner/GetBannerList', data);

//=== 最新消息 ===
export const apiGetNewsList = (data) => apiProxyWeb.post('/ArticleNews/GetArticleNewsList', data);
export const apiGetIndexBanner = (data) => apiProxyWeb.get('/Program/GetProgramsByPlace', data);
//params part - 1:主頁 2:原視節目, 3:alian廣播, 4:原視新聞, 5:文化藝術
export const apiGetNews = (data) => apiProxyWeb.get('/NewsFlash/GetItems', data);

//=== 首頁pop廣告 ===
export const apiGetHomeAdm = () => apiProxyWeb.get('/Advertise/GetAdvertiseList');

//=== 節目表 ===
export const apiGetProgramTimeList = (data) => apiProxyWeb.post('/Program/GetProgramListForPlayTime', data);

//=== 新聞文章列表 ===
export const apiGetArticleNewsList = (data) => apiProxyWeb.post('/News/GetNewsList', data);

//=== 全站搜尋 ===
export const apiSearch = (data) => apiProxyWeb.post('/ProgramSearch/List', data);

//=== 後台 ===
//=== 首頁輪播 ===
export const apiBannerEnter = () => apiProxy.get();

//=== 單元中幅廣告 ===
export const apiBannerMiddle = () => apiProxyBannerMiddle.get();
//=== 單元底部廣告 ===
export const apiBannerBottom = (data) => apiProxyBannerBottom.get('', data);

//=== 新聞top影片 ===
export const apiNewsTop = () => apiProxyMarket.get('/news');
//=== 節目top影片 ===
export const apiProgramTop = () => apiProxyMarket.get('/program');
//=== 隨機節目 ===
export const apiProgramTopic = () => apiProxyMarket.get('/topic');