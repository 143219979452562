<template>
  <div class="nav-bar-wrap">
    <div class="nav-bar-box" id="navBarBtn">
      <div class="nav-bar"></div>
      <div class="tit">IPCF&TITV</div>
    </div>
    <div class="nav-bar-area" id="navBarArea" style="display: none">
      <router-link to="/live" class="nav-bar-btn">
        <div class="icon"><img src="@/assets/img/icon_live.svg" alt="" /></div>
        <div class="txt">Live</div>
      </router-link>
      <router-link to="/radio" class="nav-bar-btn">
        <div class="icon"><img src="@/assets/img/icon_music.svg" alt="" /></div>
        <div class="txt">廣播</div>
      </router-link>
      <router-link to="/news" class="nav-bar-btn">
        <div class="icon"><img src="@/assets/img/icon_news.svg" alt="" /></div>
        <div class="txt">新聞</div>
      </router-link>
      <router-link to="/program" class="nav-bar-btn">
        <div class="icon">
          <img src="@/assets/img/icon_program.svg" alt="" />
        </div>
        <div class="txt">節目</div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavBar",
  props: ["isLive"],
};
</script>